.counters {
    background-image: url("../../../public/images/img2.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: #fff;
    padding: 40px 20px;
}

.counters .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    text-align: center;
}

.counters i {
    color: #fff;
    margin-bottom: 5px;
}

.counters .counter {
    font-size: 45px;
    margin: 10px 0;
}

@media (max-width: 700px) {
    .counters .container {
        grid-template-columns: repeat(2, 1fr);
    }
    .counters .container>div:nth-of-type(1),
    .counters .container>div:nth-of-type(2) {
        border-bottom: 1px lightskyblue solid;
        padding-bottom: 20px;
    }
}