.home {
    min-height: 70vh;
    width: 100vw;
    background-image: url("../../../public/images/img1.jpg");
    /* background-image: url("https://cdn-aekfi.nitrocdn.com/BhHUnZmQXkWPzBaLMaTftVhEvszyNTtP/assets/images/source/rev-b9d4954/blog/images/2012/11/managing-virtual-teams.jpg.webp"); */
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    padding: 0 1rem;
    position: relative;
    overflow: hidden !important;
}

.home h1 {
    color: #fff;
    font-size: 2rem;
}

.home h2 {
    color: #fff;
    font-size: 3rem;
}

.home .wave {
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    height: 11rem;
    width: 100%;
    /* background: url("../../../public/images/wave.png"); */
    background: url("../../../public/images/wave.png");
    background-size: 100rem 11rem;
    animation: waves 8s linear infinite;
    background-repeat: repeat-x;
}

.home .wave2 {
    animation-direction: reverse;
    animation-duration: 6s;
    opacity: 0.3;
}

.home .wave3 {
    animation-duration: 4s;
    opacity: 0.5;
}

@keyframes waves {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 100rem;
    }
}

@media (min-height:768px) {
    .home h1 {
        color: #fff;
        font-size: 5rem;
    }
    .home h2 {
        color: #fff;
        font-size: 3rem;
    }
}