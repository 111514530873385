/* .section-head-1 {
    margin-bottom: 60px;
    background-size: 200%;
    background-position: left;
}

.section-head-1 p {
    color: #333;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

.item {
    background: #fff;
    text-align: center;
    padding: 30px 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    margin-bottom: 30px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.7s ease 0s;
    transition: all 0.7 ease 0s;
}

.item:hover {
    background-image: linear-gradient(to bottom right, #66ffcc 0%, #ffccff 100%);
    background-position: right;
    transition: background-position 3s;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.7s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
    background: #fff;
    border-radius: 10px;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item:hover h6,
.item:hover p {
    color: #2b6777;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item .icon {
    font-size: 40px;
    margin-bottom: 25px;
    color: yellow;
    width: 90px;
    height: 90px;
    line-height: 96px;
    border-radius: 50px;
}

.item .feature_box_col_one {
    background: rgba(247, 198, 5, 0.2);
    color: #52ab98;
}

.item .feature_box_col_two {
    background: rgba(255, 77, 28, 0.15);
    color: #52ab98;
}

.item .feature_box_col_three {
    background: rgba(0, 147, 38, 0.15);
    color: #52ab98;
}

.item .feature_box_col_four {
    background: rgba(0, 108, 255, 0.15);
    color: #52ab98;
}

.item .feature_box_col_five {
    background: rgba(146, 39, 255, 0.15);
    color: #52ab98;
}

.item .feature_box_col_six {
    background: rgba(23, 39, 246, 0.15);
    color: #52ab98;
}

.item p {
    font-size: 15px;
    font-family: "Varela Round", Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 26px;
}

.item h6 {
    margin-bottom: 20px;
    font-family: "Varela Round", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #2f2f2f;
}

.communicate {
    text-align: center;
    align-items: center;
    background-image: url("../../../public/images/img3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    min-height: 35vh;
    padding: 40px 20px;
} */


/* 
.communicate h3 {
    margin-top: 3rem;
    font-size: 3rem;
} */

.feedback-div {
    margin: 5px 0px;
}

.feedback-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,800);
figure.snip1139 {
    font-family: 'Raleway', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    min-width: 220px;
    max-width: 310px;
    width: 100%;
    color: #333;
    text-align: left;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

figure.snip1139 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

figure.snip1139 img {
    max-width: 100%;
    vertical-align: middle;
}

figure.snip1139 blockquote {
    position: relative;
    background-color: #ffffff;
    padding: 25px 50px 25px 50px;
    font-size: 1.2em;
    font-weight: 500;
    text-align: left;
    margin: 0;
    line-height: 1.6em;
    font-style: italic;
}

figure.snip1139 blockquote:before,
figure.snip1139 blockquote:after {
    font-family: 'FontAwesome';
    content: "\201C";
    position: absolute;
    font-size: 50px;
    opacity: 0.3;
    font-style: normal;
}

figure.snip1139 blockquote:before {
    top: 25px;
    left: 20px;
}

figure.snip1139 blockquote:after {
    content: "\201D";
    right: 20px;
    bottom: 0;
}

figure.snip1139 .arrow {
    top: 100%;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #ffffff;
    margin: 0;
    position: absolute;
}

figure.snip1139 .author {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 25px;
    color: #000000;
    background-color: #ffffff;
    margin: 0;
    text-transform: uppercase;
}

figure.snip1139 .author h5 {
    opacity: 0.8;
    margin: 0;
    font-weight: 800;
}

figure.snip1139 .author h5 span {
    font-weight: 400;
    text-transform: none;
    padding-left: 5px;
}


/* Demo purposes only */


/* 
body {
    background-color: #212121
} */


/* Demo purposes only */


/* html {
    height: 100%;
}

body {
    background-color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    margin: 0;
    height: 100%;
} */