 .testimonials {
     position: relative;
     padding: 90px 0 60px 0;
     background: #f2f2f2;
 }
 
 .team-header {
     margin: 10px 0px;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .testimonials .testimonial-item {
     position: relative;
     margin: 0 15px 30px 15px;
     /* background: #ffffff; */
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 2px 2px;
 }
 
 .testimonials .testimonial-item:hover {
     background: #c8d8e4;
     box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
     -webkit-transition: all 0.5s ease 0s;
     transition: all 0.5s ease 0s;
     cursor: pointer;
 }
 
 .testimonials .testimonial-img {
     position: relative;
     background: #000000;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 200px;
     height: 200px;
 }
 
 .testimonials .testimonial-text {
     position: relative;
     width: 100%;
     padding: 30px 15px;
     text-align: center;
     /* color: rgb(8, 72, 83); */
     /* background: #ffffff; */
 }
 
 .testimonials .testimonial-text h3 {
     font-size: 18px;
     font-weight: 700;
     margin-bottom: 10px;
 }
 
 .testimonials .testimonial-text h4 {
     font-size: 12px;
     font-weight: 300;
     text-transform: uppercase;
 }
 
 .testimonials .testimonial-text p {
     margin: 0;
     font-size: 16px;
     font-weight: 300;
 }
 
 .testimonials .owl-nav,
 .testimonials .owl-dots {
     margin-top: 5px;
     text-align: center;
 }
 
 .testimonials .owl-dot {
     display: inline-block;
     margin: 0 5px;
     width: 12px;
     height: 12px;
     border-radius: 50%;
     background-color: #dddddd;
 }
 
 .testimonials .owl-dot.active {
     background-color: #4F84C4;
 }
 
 .details-link:hover {
     text-decoration: none;
 }
 
 @media (max-width: 575px) {
     .testimonials .testimonial-text {
         padding: 25px;
     }
 }