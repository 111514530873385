.details-container {
    margin: 20px 20px;
    /* scroll-padding-top: 0px;
    scroll-behavior: smooth;
    scroll-margin-top: 0px;
    scroll-margin: 20px;
    scroll-padding: 7vmax; */
    /* height: 100vh; */
    /* overflow-y: scroll; */
}

.img-details-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
}

.details-div {
    margin: 25px 10px;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
}

.details-div img {
    width: 400px;
    height: 400px;
}

.details-div span {
    font-size: large;
}

.inc-dec-btn {
    padding: 10px;
    margin: 5px;
    font-weight: 900;
    color: rgb(171, 227, 235);
    font-size: larger;
    cursor: pointer;
}

.details-btn {
    border: none;
    background-color: rgb(153, 225, 238);
    color: white;
    padding: 5px;
}