    @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
    /* body {
    background-color: #eeeeee;
    font-family: 'Open Sans', serif
} */
    /* .container {
    margin-top: 50px;
    margin-bottom: 50px;
} */
    
    .cards {
        margin-top: 80px;
        margin-bottom: 50px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.10rem
    }
    
    .no-service-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        width: 80vw;
    }
    
    cards-header:first-child {
        border-radius: calc(0.37rem - 1px) calc(0.37rem - 1px) 0 0
    }
    
    .cards-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    }
    
    .track {
        position: relative;
        background-color: #ddd;
        height: 7px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 60px;
        margin-top: 50px
    }
    
    .track .step {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 25%;
        margin-top: -18px;
        text-align: center;
        position: relative
    }
    
    .track .step.active:before {
        background: #22c0ff
    }
    
    .track .step::before {
        height: 7px;
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        top: 18px
    }
    
    .track .step.active .icon {
        background: #22c0ff;
        color: #fff
    }
    
    .track .icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: relative;
        border-radius: 100%;
        background: #ddd
    }
    
    .track .step.active .text {
        font-weight: 400;
        color: #000
    }
    
    .track .text {
        display: block;
        margin-top: 7px
    }
    
    .itemside {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%
    }
    
    .itemside .aside {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
    
    .img-sm {
        width: 80px;
        height: 80px;
        padding: 7px
    }
    
    ul.row,
    ul.row-sm {
        list-style: none;
        padding: 0
    }
    
    .itemside .info {
        padding-left: 15px;
        padding-right: 7px
    }
    
    .itemside .title {
        display: block;
        margin-bottom: 5px;
        color: #212529
    }
    
    p {
        margin-top: 0;
        margin-bottom: 1rem
    }
    
    .btn-warning {
        color: #ffffff;
        background-color: #22c0ff;
        border-color: #22c0ff;
        border-radius: 1px
    }
    
    .btn-warning:hover {
        color: #ffffff;
        background-color: #22c0ff;
        border-color: #22c0ff;
        border-radius: 1px
    }
    /* Requirement styles from here..!!!*******************************************************/
    
    .form-style-9 {
        max-width: 600px;
        background: #FAFAFA;
        padding: 30px;
        margin: 25% auto;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        border: 6px solid #305A72;
    }
    
    .form-style-9 ul {
        padding: 0;
        margin: 0;
        list-style: none;
        /* margin-top: 15%; */
    }
    
    .form-style-9 ul li {
        display: block;
        margin-bottom: 10px;
        min-height: 35px;
    }
    
    .form-style-9 ul li .field-style {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding: 8px;
        outline: none;
        border: 1px solid #B0CFE0;
        /* -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out; */
    }
    
    .form-style-9 ul li .field-style:focus {
        box-shadow: 0 0 5px #B0CFE0;
        border: 1px solid #B0CFE0;
    }
    
    .form-style-9 ul li .field-split {
        width: 49%;
    }
    
    .form-style-9 ul li .field-full {
        width: 100%;
    }
    
    .form-style-9 ul li input.align-left {
        float: left;
    }
    
    .form-style-9 ul li input.align-right {
        float: right;
    }
    
    .form-style-9 ul li textarea {
        width: 100%;
        height: 100px;
    }
    
    .form-style-9 ul li input[type="button"],
    .form-style-9 ul li input[type="submit"] {
        -moz-box-shadow: inset 0px 1px 0px 0px #3985B1;
        -webkit-box-shadow: inset 0px 1px 0px 0px #3985B1;
        box-shadow: inset 0px 1px 0px 0px #3985B1;
        background-color: #216288;
        border: 1px solid #17445E;
        display: inline-block;
        cursor: pointer;
        color: #FFFFFF;
        padding: 8px 18px;
        text-decoration: none;
        font: 12px Arial, Helvetica, sans-serif;
    }
    
    .form-style-9 ul li input[type="button"]:hover,
    .form-style-9 ul li input[type="submit"]:hover {
        background: linear-gradient(to bottom, #2D77A2 5%, #337DA8 100%);
        background-color: #28739E;
    }
    
    .web-purpose .form-check-label {
        margin-left: 7%;
    }
    
    .web-purpose .form-check-input {
        margin-left: 10px;
    }
    /* .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
    
    .box select {
        background-color: #178dee;
        color: white;
        /* padding: 12px; */
        width: 250px;
        border: none;
        font-size: 15px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
        -webkit-appearance: button;
        appearance: button;
        outline: none;
        margin: 5px 0px;
    }
    
    .box::before {
        content: "\f13a";
        font-family: FontAwesome;
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        text-align: center;
        font-size: 28px;
        line-height: 45px;
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.1);
        pointer-events: none;
    }
    
    .box:hover::before {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(255, 255, 255, 0.2);
    }
    
    .box select option {
        padding: 30px;
    }
    
    @media (min-width: 700px) {
        .form-style-9 {
            max-width: 700px;
            background: #FAFAFA;
            margin: 8% auto;
        }
    }