.details-container {
    /* margin-top: 30%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.ntcs-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.ntcs-col-div {
    text-align: center;
    align-items: center;
    background-image: url("../../../public/images/img3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    min-height: 35vh;
    padding: 40px 20px;
}

.image-founder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    margin-top: 10%;
    padding-top: 20%;
}

.image-founder img {
    width: 250px;
    height: 250px;
}

.founder-row {
    margin-bottom: 20%;
    margin-top: -20%;
}


/* 
.img-details-div {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.animate-charcter {
    /* text-transform: uppercase; */
    background-image: linear-gradient( -225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.img-slide {
    max-height: 300px;
}

.details-div {
    margin: 25px 10px;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
}

.inc-dec-btn {
    padding: 10px;
    margin: 5px;
    font-weight: 900;
    color: rgb(11, 91, 94);
    font-size: larger;
    cursor: pointer;
}

.details-btn {
    border: none;
    background-color: teal;
    color: white;
    padding: 5px;
}

@media (max-width:450px) {
    .align-center {
        margin-top: 15%;
    }
    .details-container {
        margin-top: 50%;
    }
    .founder-row {
        margin-bottom: 20%;
        margin-top: -45%;
    }
    .details-container {
        margin-top: 10%;
    }
}