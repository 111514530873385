.pt-5 .container .row .section-head p {
    font-size: 2rem;
    color: #333;
    padding: 1rem 0;
}

.section-head {
    margin-bottom: 60px;
    text-align: center;
    margin-top: -8rem;
}

.section-head p {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}

.item {
    background: #fff;
    text-align: center;
    padding: 30px 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    margin-bottom: 30px;
    margin-top: -1rem;
    border: 5px solid rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item:hover {
    background: #c8d8e4;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    cursor: pointer;
}

.item:hover .item,
.item:hover span.icon {
    background: #fff;
    border-radius: 10px;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item h6 {
    font-size: 2rem;
}

.details-btn {
    background-color: rgb(126, 224, 248);
    width: 150px;
    height: 40px;
    font-size: medium;
    border-radius: 40%;
}

.item:hover h6,
.item:hover p {
    color: #2b6777;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.item .icon {
    font-size: 40px;
    margin-bottom: 25px;
    color: yellow;
    width: 90px;
    height: 90px;
    line-height: 96px;
    border-radius: 50px;
}

.item .feature_box_col_one {
    background: rgba(247, 198, 5, 0.2);
    color: #52ab98;
}

.item .feature_box_col_two {
    background: rgba(255, 77, 28, 0.15);
    color: #52ab98;
}

.item .feature_box_col_three {
    background: rgba(0, 147, 38, 0.15);
    color: #52ab98;
}

.item .feature_box_col_four {
    background: rgba(0, 108, 255, 0.15);
    color: #52ab98;
}

.item .feature_box_col_five {
    background: rgba(146, 39, 255, 0.15);
    color: #52ab98;
}

.item .feature_box_col_six {
    background: rgba(23, 39, 246, 0.15);
    color: #52ab98;
}

.item p {
    font-size: 15px;
    line-height: 26px;
}

.item h6 {
    margin-bottom: 20px;
    color: #2f2f2f;
}

.details-link:hover {
    text-decoration: none;
}