    .topbar {
        height: 50px;
        background-color: white !important;
        display: flex;
        justify-content: center;
        /* width: 100%; */
        width: 100vw;
        font-size: 30x;
        margin-top: 0%;
    }
    
    .topContainer {
        z-index: 99;
        position: fixed;
        height: 50px;
        margin-top: 0%;
        background-color: white !important;
    }
    
    .softman-logo {
        color: white;
        text-decoration: none;
    }
    
    .softman-logo:hover {
        font-weight: 600;
        text-shadow: 2px 2px gray;
        font-size: large;
    }
    
    .topContainer {
        width: 100vw;
        /* max-width: 1024px; */
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    .logo {
        font-weight: 500;
    }
    
    .log-reg-btn {
        margin-right: 0px;
        padding: 5px 10px;
    }
    
    .topButton {
        border: none;
        padding: 6px 10px;
        cursor: pointer;
        color: teal;
        margin-right: 5px;
        border: none;
        font-size: 12px;
    }
    
    .topButton {
        background-color: #22ceec;
        color: white;
        border: none;
    }
    
    .select-div {
        padding: 4px 4px 4px 4px;
        text-decoration: none;
        background-color: #22ceec;
        color: white;
        border: none;
        font-size: 14px;
        margin-left: 8px;
    }
    /* Dropdown Button */
    
    .dropbtn {
        background-color: #22ceec;
        color: white;
        padding: 3px 8px;
        font-size: 16px;
        border: none;
        max-width: 120px;
    }
    /* The container <div> - needed to position the dropdown content */
    
    .dropdown {
        position: relative;
        display: inline-block;
    }
    /* Dropdown Content (Hidden by Default) */
    
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
    /* Links inside the dropdown */
    
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    /* Change color of dropdown links on hover */
    
    .dropdown-content a:hover {
        background-color: #ddd;
    }
    /* Show the dropdown menu on hover */
    
    .dropdown:hover .dropdown-content {
        display: block;
    }
    /* Change the background color of the dropdown button when the dropdown content is shown */
    
    .dropdown:hover .dropbtn {
        background-color: white;
        color: #22ceec;
        border: none;
    }
    
    .cart-btn {
        border: none;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        background-color: white;
        text-decoration: none;
        font-style: none;
        border: none;
    }
    
    .cart-val {
        width: 16px;
        color: white;
        height: 16px;
        position: absolute;
        margin-top: -8px;
        margin-left: -7px;
        border-radius: 50%;
        background-color: rgb(250, 27, 11);
    }
    
    .cart-val-btn {
        font-size: 20px;
        background: transparent;
    }
    
    @media (max-width: 800px) {
        .topbar {
            height: 50px;
            background-color: white !important;
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 10px;
        }
        .top-logo {
            font-size: 20px;
        }
        .cart-val {
            width: 20px;
            height: 20px;
        }
    }