@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* text-transform: capitalize; */
    transition: all 0.2s linear;
    text-decoration: none;
    scroll-margin-top: 0px;
}

html {
    font-size: 62.5%;
    scroll-padding: 7vmax;
    scroll-behavior: smooth;
    scroll-margin-top: 0px;
}

body {
    overflow-x: hidden;
    scroll-margin-top: 0px;
}