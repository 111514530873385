    .navbar {
        box-shadow: 2px 2px 8px 0px #bdb9b9;
        width: 100%;
        height: 64px;
        background: #fff;
        color: #000;
        z-index: 50;
        margin-top: 0px;
        position: fixed;
    }
    
    .navbar-container {
        display: flex;
        justify-content: space-between;
        height: 64px;
        align-items: center;
    }
    
    .chk-box {
        display: none;
    }
    
    .menu-items {
        order: 2;
        display: flex;
        /* font-size: 30px; */
    }
    
    .menu-items li {
        list-style: none;
        margin-right: 0.5rem;
        font-size: 1.5rem;
    }
    
    .navbar a {
        color: #444;
        text-decoration: none;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
    }
    
    .menu-items li i {
        margin-right: 3px;
    }
    
    .menu-items li a {
        color: gray;
        margin: 3px 5px;
    }
    
    .menu-items li a:hover {
        color: #02c8eb;
    }
    
    @media (max-width: 768px) {
        .navbar {
            /* opacity: 0.95; */
            width: 100vw;
        }
        .navbar-container input[type="checkbox"],
        .navbar-container .hamburger-lines {
            display: block;
        }
        .navbar-container {
            display: block;
            position: relative;
            height: 64px;
        }
        .navbar-container input[type="checkbox"] {
            position: absolute;
            display: block;
            height: 32px;
            width: 30px;
            top: 20px;
            left: 20px;
            z-index: 5;
            opacity: 0;
            cursor: pointer;
        }
        .navbar-container .hamburger-lines {
            display: block;
            height: 28px;
            width: 35px;
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .navbar-container .hamburger-lines .line {
            display: block;
            height: 4px;
            width: 100%;
            border-radius: 10px;
            background: #333;
        }
        .navbar-container .hamburger-lines .line1 {
            transform-origin: 0% 0%;
            transition: transform 0.3s ease-in-out;
        }
        .navbar-container .hamburger-lines .line2 {
            transition: transform 0.2s ease-in-out;
        }
        .navbar-container .hamburger-lines .line3 {
            transform-origin: 0% 100%;
            transition: transform 0.3s ease-in-out;
        }
        .navbar .menu-items {
            padding-top: 100px;
            background: #fff;
            height: 100vh;
            max-width: 350px;
            transform: translate(-150%);
            display: flex;
            flex-direction: column;
            margin-left: -40px;
            padding-left: 70px;
            transition: transform 0.5s ease-in-out;
            box-shadow: 5px 0px 10px 0px #aaa;
            overflow: scroll;
        }
        .navbar .menu-items li {
            margin-bottom: 1.8rem;
            font-size: 1.3rem;
            font-weight: 500;
            /* padding: 2px 4px; */
        }
        .navbar .menu-items li {
            margin-right: 50px;
        }
        .logo {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 2.5rem;
        }
        .navbar-container input[type="checkbox"]:checked~.menu-items {
            transform: translateX(0);
        }
        .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
            transform: rotate(45deg);
        }
        .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
            transform: scaleY(0);
        }
        .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
            transform: rotate(-45deg);
        }
        .menu-items {
            margin-right: 30px;
        }
        .menu-items li {
            list-style: none;
            margin-right: 0.5rem;
            font-size: 1.5rem;
            padding: 15px 10px;
            /* background-color: #48d8f1; */
            color: white;
            border: 2px solid rgb(243, 241, 241);
            /* border-radius: 30%; */
        }
        .menu-items li a {
            color: #48d8f1;
        }
        .menu-items li i {
            padding-right: 5px;
        }
        .menu-items li:hover {
            background-color: white;
            color: #48d8f1;
            cursor: pointer;
        }
        .dropbtn {
            padding: 12px 12px;
            max-width: 200px;
        }
        .dropdown-content {
            /* max-width: 200px; */
            min-width: 140px;
            background-color: #f8f9fa;
        }
        .dropdown-content a {
            padding: 6px 8px;
        }
    }
    
    @media (max-width: 500px) {
        .navbar-container input[type="checkbox"]:checked~.logo {
            display: none;
        }
    }