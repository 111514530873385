h4 {
    text-align: center;
    color: rgb(1, 150, 177);
    font-size: x-large;
}

.accordion input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.accordion-wrapper {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    width: 700px;
    margin: 0 auto;
}

.accordion {
    width: 100%;
    color: white;
    overflow: hidden;
    margin-bottom: 16px;
}

.accordion:last-child {
    margin-bottom: 0;
}

.accordion-label {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px;
    background: #b8caca;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
}

.accordion-label::after {
    content: "\276F";
    width: 16px;
    height: 16px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.accordion-content {
    max-height: 0;
    padding: 0 16px;
    color: rgb(171, 177, 182);
    background: white;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.accordion-content p {
    margin: 0;
    color: rgba(4, 57, 94, .7);
    font-size: 18px;
}

input:checked+.accordion-label {
    background: rgb(178, 187, 194);
}

input:checked+.accordion-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

input:checked~.accordion-content {
    max-height: 100vh;
    padding: 16px;
}

@media (max-width:700px) {
    .accordion-wrapper {
        width: 300px;
        margin: 0 auto;
    }
}