    .whatsapp_float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 100px;
        right: 40px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 35px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
        animation: my-animation infinite 3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* 
.whatsapp-icon {
    margin-top: 20px;
} */
    
    .whatsapp-icon {
        font-size: x-large;
    }
    
    @keyframes my-animation {
        from {
            width: 50px;
            height: 50px;
        }
        to {
            width: 60px;
            height: 60px;
            box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
        }
    }
    
    .whatsapp-contacct:hover {
        text-decoration: none;
    }
    /* for mobile */
    
    @media (max-width: 767px) {
        /* .whatsapp-icon {
        margin-top: 10px;
    } */
        .whatsapp_float {
            position: fixed;
            width: 40px;
            height: 40px;
            bottom: 50px;
            right: 10px;
            font-size: xx-large;
        }
        .whatsapp-icon {
            font-size: xx-large;
        }
    }