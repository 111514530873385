.about {
    min-height: 35vh;
    width: 100vw;
    top: -3rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    line-height: 5rem;
}

.about .row {
    line-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1rem;
}

.about .row .content {
    text-align: center;
    padding: 2px 10px;
}

.about .row .content h1 {
    font-size: 1.5rem;
    color: black;
    padding: 2px 10px;
}

.about .row .content p {
    font-size: 1.2rem;
    color: #333;
    padding: 1rem 0;
    padding: 5px 15px;
}

.btn-more {
    margin-bottom: 30px;
    font-size: 20px;
    border-style: none;
    background-color: transparent;
    color: rgb(0, 183, 255);
    border: 1px solid rgb(7, 226, 255);
    padding: 1px 2px;
}

@media (min-width:800px) {
    .about {
        /* height: 35vh; */
        /* top: 0.8rem; */
        width: 100vw;
        overflow: hidden;
        line-height: 6rem;
    }
    .about .row .content p {
        font-size: 1.8rem;
        color: #333;
        padding: 1rem 0;
        padding: 5px 15px;
    }
}

@media (min-height:768px) {
    .about {
        min-height: 15vh;
        /* top: 0.8rem; */
    }
}