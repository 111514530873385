.more-service {
    background-color: transparent;
    opacity: 0.7;
}

.more-service img {
    padding: 15px 5px;
    margin: 15px 10px;
    height: 200px;
    width: 200px;
}

.more-service-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}