/* .lds-facebook {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
} */

.loader-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.ldr-animate-charcter {
    background-image: linear-gradient( -225deg, #231557 0%, #1dbce4 29%, #1371ff 67%, #00ccff 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}


/* 
.loader-text-div {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.ntcs-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25%;
}

.loader-main {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100vw;
    height: 100vh; */
}

.loader-main .lds-facebook {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #adafb1;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 34px;
    }
    50%,
    100% {
        top: 24px;
        height: 15px;
    }
}