.clients {
    position: relative;
    padding: 90px 0;
    text-align: center;
    margin-top: -10rem;
    margin-bottom: -10rem;
}

.client-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 300px;
    width: 300px;
}

.card {
    background-color: #e8f6f8;
}

.card .image :hover {
    background-color: #aa9494;
    cursor: pointer;
}

.client-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px 6px;
    height: 350px;
    width: 300px;
    border: none;
}

.client-card :hover {
    background: #c8d8e4;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    cursor: pointer;
}

.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients .section-header p {
    padding-bottom: 10px;
    margin-top: 2.5rem;
    text-align: center;
    font-size: 2rem;
    color: #333;
    padding: 1rem 0;
}

.clients img {
    max-width: 100%;
    /* opacity: 0.9; */
    transition: 0.3s;
    padding: 15px 0;
    object-fit: cover;
    border-radius: 50%;
}

.clients .owl-nav,
.clients .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.clients .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
}

.clients .owl-dot.active {
    background-color: #4F84C4;
}

.info .app-text {
    font-size: medium;
}

.communicate {
    text-align: center;
    align-items: center;
    background-image: url("../../../public/images/img3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
    min-height: 35vh;
    padding: 40px 20px;
}

.communicate h3 {
    margin-top: 3rem;
    /* font-size: 3rem; */
}

@media (min-height:768px) {
    .communicate {
        min-height: 15vh;
        /* top: 0.8rem; */
    }
}