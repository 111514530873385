.login-div {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loginInput {
    height: 30px;
    padding: 10px;
}

.loginButton {
    border: none;
    padding: 10px 20px;
    background-color: #0ba6c2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}

.loginButton:disabled {
    background-color: #0071c28c;
    cursor: not-allowed;
}