.contact {
    text-align: center;
    align-items: center;
}

.contact .heading {
    margin-bottom: 5rem;
}

.contact-in {
    width: 80%;
    height: auto;
    margin: auto auto 5rem auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px #666;
}

.contact-map {
    width: 100%;
    height: auto;
    flex: 50%;
}

.contact-map iframe {
    width: 100%;
    height: 100%;
}

.contact-form {
    width: 100%;
    height: auto;
    flex: 50%;
    text-align: left;
}

.contact-form-txt {
    margin-left: 2rem;
    width: 95%;
    height: 40px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
}

.contact-form-email {
    margin-left: 2rem;
    width: 95%;
    height: 40px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
    text-transform: lowercase;
}

.contact-form-txt::placeholder {
    color: #aaa;
    font-size: 1.5rem;
}

.contact-form-email::placeholder {
    color: #aaa;
    font-size: 1.5rem;
}

.contact-form-txtarea {
    margin-left: 2rem;
    width: 95%;
    height: 130px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 10px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
    font-family: 'Poppins', sans-serif;
}

.contact-form-txtarea::placeholder {
    color: #aaa;
    font-size: 1.5rem;
}

.contact-form-btn {
    margin-left: 2rem;
    outline: none;
    border: none;
    border-radius: 5rem;
    background: white;
    border-style: groove;
    border-color: #002e5f;
    font-size: 1.5rem;
    cursor: pointer;
    height: 3.5rem;
    width: 15rem;
    box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .3);
}

.contact-form-btn:hover {
    letter-spacing: .1rem;
    opacity: .9;
    color: white;
    background: #002e5f;
}

.contact-form-phone {
    margin-left: 2rem;
    width: 95%;
    height: 40px;
    color: #000;
    border: 1px solid #bcbcbc;
    border-radius: 50px;
    outline: none;
    margin-bottom: 20px;
    padding: 15px;
}

.contact-form-phone::placeholder {
    color: #aaa;
    font-size: 1.5rem;
}